<template>
	<div class="p-formgrid p-grid translation-item col-12"
		style="font-weight: 700;background-color:#f8f9fa;margin-top: 20px;">
		<div class='md:col-3'>实体1</div>
		<div class='md:col-2'>关系</div>
		<div class='md:col-3'>实体2</div>
		<div class='md:col-2'>时间</div>
		<div class='md:col-1'></div>
		<div class='md:col-1'></div>
	</div>
	<div :style='list_style'>
		<div v-for="(item, j) of triple_list" :key="j">
			<div :title='item.triple_time?item.triple_time.name:"未指定时间"' class='translation-item-2 col-12' :this_key='j'
				:id='item.id'>
				<!-- <i class="pi pi-plus-circle" style="margin-top:auto;float: left;" title="增加子列表"
				@click="item.show_spcific_list=!item.show_spcific_list" /> -->
				<!-- 				<div class='md:col-1'>
					<i class="img-number">{{j+1}}</i>
				</div> -->
				<div class='md:col-3' :title='"实体数量："+item.first_entity.entity_id_list.length'>
					<span><i style="margin-right: 5px;" class="img-number">{{j+1}}</i>{{item.first_entity.name}}<i class="pi pi-chevron-circle-down" @click="item.show_spcific_list=!item.show_spcific_list"
								style="margin-top:3px;float: right;" title="展开/关闭子列表" /></span>
				</div>
				<div class='md:col-2' :title='"关系属性："+item.relation_entity.entity_id_list.length'>
					<span>{{item.relation_entity.name}}</span>
				</div>
				<div class='md:col-3' :title='"实体数量："+item.second_entity.entity_id_list.length'>
					<span>{{item.second_entity.name}}<i class="pi pi-plus-circle" @click="addSpecificList(item,j)" style="margin-top:3px;float: right;"
								title="增加子列表" /></span>
				</div>
				<div class='md:col-2'>
					{{item.triple_time?item.triple_time.name:"空"}}
				</div>
				<div class='md:col-1'>
					<i class="pi pi-trash" @click="deleteTripleList(j)" style="margin-top:3px;" title="删除列表" />
				</div>
				<div class='md:col-1'>
					<i class="pi pi-pencil" @click="editTripleItem(item,j)" style="margin-top:3px;" title="编辑三元组" />
				</div>
			</div>
			<div v-if='item.show_spcific_list==true' :this_key='j' :id='item.id'>
				<div class='translation-item-2 col-12' style='background-color: var(--surface-ground)'
					v-for="(specific_item, k) of item.specific_list" :key="k">
					<SpecificList class='md:col-3' ref='first_entity' @selectEntityId='selectEntityId'
						:title='specific_item.first_entity_list.id' :entity_tag_copy='"first_entity_list"'
						:specific_index_copy='k' :triple_index_copy='j'
						:specific_entity_copy='specific_item.first_entity_list' :entity_copy='item.first_entity'>
					</SpecificList>
					<SpecificList class='md:col-2' ref='relation_entity' @selectEntityId='selectEntityId'
						:title='specific_item.relation_entity_list.id' :entity_tag_copy='"relation_entity_list"'
						:specific_index_copy='k' :triple_index_copy='j'
						:specific_entity_copy='specific_item.relation_entity_list' :entity_copy='item.relation_entity'>
					</SpecificList>
					<SpecificList class='md:col-3' ref='second_entity' @selectEntityId='selectEntityId'
						:title='specific_item.second_entity_list.id' :entity_tag_copy='"second_entity_list"'
						:specific_index_copy='k' :triple_index_copy='j'
						:specific_entity_copy='specific_item.second_entity_list' :entity_copy='item.second_entity'>
					</SpecificList>
					<SpecificList v-if='specific_item.triple_time_list!=undefined' class='md:col-2' ref='triple_time' @selectEntityId='selectEntityId'
						:title='specific_item.triple_time_list.id' :entity_tag_copy='"triple_time_list"'
						:specific_index_copy='k' :triple_index_copy='j'
						:specific_entity_copy='specific_item.triple_time_list' :entity_copy='item.triple_time'>
					</SpecificList>
					<div class='md:col-1'>
						<i class="pi pi-trash" style="float: left;" title="删除子列表"
							@click="deleteTripleSpecificList(j,k)" />
					</div>
				</div>
			</div>
		</div>
		<!-- <div style='margin-bottom: 100px;'></div> -->
	</div>

</template>

<script>
	// import NetWorkService from '../../service/NetWorkService.js';
	import SpecificList from './SpecificList.vue';
	export default {
		name: '',
		props: {
			triple_list_copy: {
				default: null,
				type: Object
			},
			list_style_copy: {
				default: null,
				type: Object
			}
		},
		components: {
			'SpecificList': SpecificList,
		},
		data() {
			return {
				triple_list: this.triple_list_copy, //此后para可在methods中操作
				list_style: this.list_style_copy,
			}
		},
		// networkService:null,
		created() {
			// this.networkService = new NetWorkService();
		},
		mounted() {

		},
		methods: {
			addSpecificList(item, j) {
				var tmp_list_item = {
					"show_id_list": false,
					"first_entity_list": {
						"name": item.first_entity.name,
						"id": item.first_entity.entity_id_list[0],
					},
					"relation_entity_list": {
						"name": item.relation_entity.name,
						"id": item.relation_entity.entity_id_list[0],
					},
					"second_entity_list": {
						"name": item.second_entity.name,
						"id": item.second_entity.entity_id_list[0],
					},
					"triple_time_list": {
						"name": item.triple_time.name,
						"id": item.triple_time.entity_id_list[0],
					},
				};
				// console.log('列表对象',tmp_list_item);
				this.$emit('addSpecificListForTriple', tmp_list_item, j);
			},
			deleteTripleList(index) {
				this.$emit('deleteTripleList', index);
			},
			deleteTripleSpecificList(triple_index, specific_index) {
				this.$emit('deleteTripleSpecificList', triple_index, specific_index);
			},
			selectEntityId(triple_index, specific_index, entity_tag, entity_id) {
				// console.log('triple_index',triple_index);
				// console.log('specific_index',specific_index);
				// console.log('entity_id',entity_id);
				// console.log('entity_tag',entity_tag);
				this.$emit("selectTripleEntityId", triple_index, specific_index, entity_tag, entity_id)
			},
			showOperateList(e, j) {
				var op_list_id = this.triple_list[j].id + '_op_list';
				this.triple_list[j].show_operate_list = !this.triple_list[j].show_operate_list;
				if (this.triple_list[j].show_operate_list == false) return;
				let el = document.getElementById(op_list_id);
				// console.log('对象',el);
				//让标注框跟随移动
				let disX = e.clientX - 15;
				// console.log('disX',disX);
				let disY = e.clientY + 10;
				el.style.left = disX + 'px';
				el.style.top = disY + 'px';
			},
			editTripleItem(item, j) {
				this.triple_list[j].show_operate_list = false;
				this.$emit('editTripleItem', item, j);
				// console.log('当前元组',item);
			},
		},
	}
</script>

<style scoped lang="scss">
	.img-number {
		float: left;
		// font-size: 18px;
		// margin-left: 5px;
		// font-weight: 700;
		// color:var(--primary-color);
		opacity: 0.5;
	}

	.translation-item {
		display: flex;
		align-items: center;
		border-top: 1px solid #ededed;
		padding: 2px 0px 2px 0px;
	}

	.translation-item-2 {
		display: flex;
		align-items: center;
		// border-top: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
		padding: 2px 0px 2px 0px;
	}

	.translation-item-2 i:hover {
		font-weight: 700;
		color: var(--primary-color);
	}

	// .word-list {
	// 	z-index: 3;
	// 	position: absolute;
	// 	float: left;
	// 	width: auto;
	// 	max-height: 140px;
	// 	overflow-y: auto;
	// 	overflow-x: auto;
	// 	margin-top: 0.3125rem;
	// }

	.operate-list {
		z-index: 3;
		position: absolute;
		top: 0px;
		// float: left;
		// margin-left: -5px;
		width: auto;
		// margin-right: 10px;
		max-height: 140px;
		overflow-y: auto;
		overflow-x: auto;
		// margin-top: 20px;
	}

	.operate-list div {
		margin-top: 5px;
	}

	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}
</style>
